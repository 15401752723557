import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'home',
        component: () => import('../views/Home.vue')
    },
    {
        path: '/item',
        name: 'item',
        component: () => import('../views/item')
    },
    {
        path: '/page',
        name: 'page',
        component: () => import('../views/page')
    },
    {
        path: '/memos',
        name: 'memos',
        component: () => import('../views/memos')
    },
    {
        path: '/java/library',
        name: 'javaLibrary',
        component: () => import('../views/java/mavenLibrary')
    },
    {
        path: '/java/item',
        name: 'javaItem',
        component: () => import('../views/java/item')
    },
    {
        path: '/json',
        name: 'json',
        component: () => import('../views/json')
    },
    {
        path: '/login',
        name: 'login',
        component: () => import('../views/Login.vue')
    },
    {
        path: '*',
        name: 'error',
        component: () => import('../views/err/404')
    }
]

const router = new VueRouter({
    mode: 'history', // 去掉url中的#
    routes
})

export default router
