<template>
  <div id="app">
    <router-view :key="keepKey"></router-view>
  </div>
</template>

<script>
export default {
  computed: {
    /**
     * 持久化路由的key值
     * @returns {string}
     */
    keepKey() {
      // 全量URL路径
      return this.$route.fullPath
    }
  }
}
</script>

<style scoped>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>
