import pageRender from './page/PageRender'
import {each} from 'lodash'
import Vue from 'vue'

const coms = {
    pageRender
}

each(coms, (item, key) => {
    let cpName = key.replace(/([A-Z])/g, '-$1').toLowerCase()
    if (cpName && cpName[0] === '-') {
        cpName = cpName.replace('-', '')
    }
    Vue.component(cpName, item)
})
