<template>
  <div v-loading="loading">
    <div v-if="errFlag">
      {{ errMsg }}
    </div>
    <component v-if="type === 'normal'" :is="normal">1</component>
    <component v-if="type === 'normal'" :is="normal">1</component>
  </div>
</template>

<script>
export default {
  name: 'page-render',
  props: {},
  data() {
    return {
      loading: true,
      errFlag: false,
      errMsg: '',
      type: '',
      normal: null
    }
  },
  created() {
    // page的渲染是递归实现的，获取Item的page字段，如果没有，直接报错
    let page = {
      // 渲染类型，这里是普通渲染
      type: 'normal',
      data: {
        template: '<div @click="show">123{{c2}}</div>',
        data: {
          c2: 12
        },
        methods: {
          show: 'this.c2++'
        }
      }
    }
    console.log(JSON.stringify(page))
    page = JSON.stringify(page)
    page = JSON.parse(page)
    const test = {
      page: page
    }
    this.render(test.page, () => {
      this.loading = false
    })
  },
  methods: {
    render(page, callback) {
      console.log(page)
      if (page.type === 'normal') {
        this.normal = {
          template: page.data.template
        }

        // 创建实体数据函数
        let dataFunction = new Function('return ' + JSON.stringify(page.data.data))
        this.normal.data = dataFunction

        // 创建每个方法
        let methods = {}
        for (let i in page.data.methods) {
          let method = page.data.methods[i]
          methods[i] = new Function(method)
        }
        this.normal.methods = methods
      }
      this.type = page.type
      console.log(this.$data)
      callback()
    }
  }
}
</script>
