import cookie from 'js-cookie'
import router from './router'

// 白名单，必须全小写
const whiteList = ['/login']

router.beforeEach((to, from, next) => {
    if (cookie.get('token')) {
        if (to.path === '/login') {
            next({path: '/'})
        } else {
            next()
        }
    } else {
        // 没有token
        let code = to.path ? to.path : '-1'
        code = code.toLowerCase()
        if (whiteList.indexOf(code) !== -1) {
            // 在免登录白名单，直接进入
            next()
        } else {
            next(`/login?redirect=${to.fullPath}`) // 否则全部重定向到登录页
        }
    }
})
